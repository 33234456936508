<template>
<g-sales-counter/>
</template>

<script>
import GSalesCounter from "../../components/GSalesCounter";
export default {
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Panel - Create a New Order | %s',
    meta: [
      {name: 'robots', content: 'noindex'},
      {name: 'googlebot', content: 'noindex'}
    ]
  },
  components: {GSalesCounter},
}
</script>

